<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="base-button font-weight-regular"
      :color="cancel ? 'white' : color"
      :outlined="cancel || toggleable"
      :class="{ hover: hover && !isIcon, 'font--text': cancel, cancel: cancel, 'v-btn-not-icon': !isIcon }"
      :dark="!$attrs.disabled"
      :loading="loading"
      :style="buttonStyle"
      :icon="isIcon"
    >
      <v-icon v-if="icon" v-text="icon" :size="iconSize" :color="cancel ? 'white' : buttonStyle.iconColor"></v-icon>
      {{ computedText }}
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    /**
     * @type {('primary'|'success'|'error')} color=primary kolor
     */
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '16px',
    },
    icon: {
      type: String,
      default: '',
    },
    translationPath: {
      type: String,
      default: '',
    },
    isPlural: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    /**
     * text that should not be translated, e.g layer card text.
     */
    straightText: {
      type: String,
      default: '',
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    translationMethod() {
      return this.isPlural ? 'tc' : 't';
    },
    computedText() {
      return this.straightText
        ? this.straightText
        : this.$i18n[this.translationMethod](this.translationPath, this.amount);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  &.v-btn.v-btn-not-icon {
    min-width: 70px !important;
    border-radius: 2px !important;
    & .v-btn__content {
      & .v-icon {
        padding-right: 6px !important;
      }
    }
  }
  &.v-btn {
    letter-spacing: 0.0178571429em !important;
    &.v-size--default {
      margin-right: 18px;
      font-size: 14px !important;
      height: 32px !important;
      padding: 8px 16px;
    }
    &.hover {
      filter: brightness(105%);
    }
    & .v-btn__content {
      text-transform: initial;
      font-weight: 500;
      & .v-icon {
        .v-btn-not-icon {
          padding-right: 6px !important;
        }
      }
    }
    &.cancel {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
