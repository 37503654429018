import { GeoJSON } from 'ol/format';
import { getGeometryCentroid } from '@/assets/js/mapUtils';
export default {
  methods: {
    clearLayer(id, group) {
      this.getLayerById(id, group).getSource().clear();
    },
    createGeomAndFitView(geometry, options = {}) {
      const olGeometry = new GeoJSON().readGeometry(geometry, {
        dataProjection: geometry.crs.properties.name,
        featureProjection: this.$_config.defaultEpsg,
      });
      this.fitView(olGeometry, {
        ...options,
        ...(options.isDrawMarker ? { markerGeometry: getGeometryCentroid(olGeometry) } : {}),
      });
    },
    fitView(geometry, options = {}) {
      if (options.isDrawMarker) {
        this.drawMarker(options.markerGeometry, options);
      }
      this.map.getView().fit(geometry, { duration: 500, maxZoom: 20, ...options });
    },
    getInteractionByName(name) {
      return this.map
        .getInteractions()
        .getArray()
        .find(interaction => interaction.get('name') == name);
    },
    getLayerById(id, group) {
      if (!group) {
        return this.map
          ?.getLayers()
          .getArray()
          .find(layer => layer.get('id') == id);
      } else {
        return this.map
          .getLayers()
          .getArray()
          .find(g => g.get('id') == group)
          .getLayersArray()
          .find(layer => layer.get('id') == id);
      }
    },
    getControlByName(name) {
      return this.map
        .getControls()
        .getArray()
        .find(control => control.get('name') === name);
    },
    getLayerByIdEmit({ id, callback }) {
      callback(this.getLayerById(id));
    },
  },
  mounted() {
    this.$root.$off('getLayerById');
    this.$root.$on('getLayerContextById', this.getLayerByIdEmit);
    this.$root.$off('createGeomAndFitView');
    this.$root.$on('createGeomAndFitView', this.createGeomAndFitView);
  },
};
