import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Circle, Fill, Stroke, Style } from 'ol/style';
export default {
  methods: {
    addFeatureHoverLayer(layerId = 'featureHoverLayer') {
      const newLayer = new VectorLayer({
        id: layerId,
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.6)',
          }),
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 3,
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, 1)',
            }),
            fill: new Fill({
              color: 'rgba(255, 0, 0, 0.6)',
            }),
          }),
        }),
      });
      this.map?.addLayer(newLayer);
    },
    toggleFeatureHover(feature, value, layerId = 'featureHoverLayer', zoomTo = false) {
      let layer = this.getLayerById(layerId);
      if (!value) {
        if (layer) this.clearLayer(layerId);
        return;
      }
      if (!layer) {
        this.addFeatureHoverLayer(layerId);
        layer = this.getLayerById(layerId);
      } else {
        this.clearLayer(layerId);
      }
      const features = [];
      (Array.isArray(feature) ? feature : [feature]).forEach(f => {
        const olGeometry = new GeoJSON().readGeometry(f.geometry, {
          dataProjection: this.$_config.defaultEpsg,
          featureProjection: this.$_config.defaultEpsg,
        });
        features.push(new Feature({ geometry: olGeometry }));
      });
      layer?.getSource().addFeatures(features);
      if (zoomTo) this.map?.getView().fit(layer.getSource().getExtent());
    },
  },
  mounted() {
    this.$root.$off('toggleFeatureHover');
    this.$root.$on('toggleFeatureHover', this.toggleFeatureHover);
  },
};
