<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters class="wrapper px-3" :class="{ 'print-wrapper': isQuickPrint }">
        <v-col cols="auto" :class="{ 'no-print': isQuickPrint }">
          <v-row no-gutters>
            <the-navbar-tools-panel-items :items="filteredTools" @coordinates="turnOffCoordinatesInteraction">
              <template #measurements="data">
                <the-navbar-tools-measurements v-bind="data" />
              </template>
              <template #addTask="data">
                <the-navbar-top-panel-menu
                  :attach="true"
                  :has-default-activator="false"
                  v-bind="{ ...data, tools: taskTools }"
                  :path="data.name"
                >
                  <template #menu="{ on: onMenu }">
                    <v-icon color="rgba(0,0,0, 0.7)" size="19" v-on="{ ...onMenu, ...data.on }" v-text="data.icon" />
                  </template>
                </the-navbar-top-panel-menu>
              </template>
              <template #searchObjects="data">
                <the-navbar-tools-panel-search-objects v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #searchParcel="data" v-if="parcelsProvider === 'system'">
                <the-navbar-tools-panel-search
                  v-bind="{ ...data, layer: 'parcels' }"
                  v-on="{ ...data.onTooltip, ...data.on }"
                />
              </template>
              <template #uldk="data">
                <the-navbar-tools-panel-uldk v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #databoxData="data">
                <the-navbar-tools-panel-databox-data v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #routingService="data">
                <the-navbar-tools-panel-routing-service v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #investmentAreaSearch="data">
                <the-navbar-tools-panel-investment-area-search v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #searchAddress="data">
                <the-navbar-tools-panel-search
                  v-bind="{ ...data, layer: 'address_points' }"
                  v-on="{ ...data.onTooltip, ...data.on }"
                />
              </template>
              <template #searchGoogle="data">
                <the-navbar-tools-panel-search
                  v-bind="{ ...data, layer: 'google' }"
                  v-on="{ ...data.onTooltip, ...data.on }"
                />
              </template>
              <template #zdmStreets="data">
                <the-navbar-tools-zdm-streets v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" class="pt-1" />
              </template>
              <template #reverseGeocoder="data">
                <the-navbar-tools-panel-reverse-geocoder v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #spatialBookmarks="data">
                <the-navbar-tools-panel-spatial-bookmarks v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #MOBILE="data">
                <the-navbar-tools-mobile-button v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #snappingConfig="data">
                <the-navbar-tools-snapping-config-button v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
              <template #zdmPowerSource="data">
                <the-navbar-tools-zdm-power-source-button v-bind="data" v-on="{ ...data.onTooltip, ...data.on }" />
              </template>
            </the-navbar-tools-panel-items>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <the-navbar-tools-panel-map-info />
        </v-col>
      </v-row>

      <main-large-dialog
        :is-visible.sync="isMobileVisible"
        v-if="isDialogLoaded"
        :title="$i18n.t('dialog.title.mobile')"
        disagree-text="dialog.disagreeClose"
        :is-agree-visible="false"
      >
        <template #body>
          <div class="pa-6">
            <v-subheader
              class="font--text text-subtitle-1 font-weight-bold text-center"
              v-text="$i18n.t('dialog.body.scanQr')"
              style="height: auto"
            >
            </v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap text-left text-body-2">
                    1.
                    <i18n path="dialog.body.mobile_1" tag="span">
                      <template #link>
                        <a target="_blank" :href="url">link</a>
                      </template>
                    </i18n>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap text-left text-body-2">
                    2.
                    <i18n path="dialog.body.mobile_2" tag="span"> </i18n>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <qrcode-vue :value="url" size="200" level="H"></qrcode-vue>
            <span v-if="mobileAppMetadata" class="app-version-text">
              {{ mobileVersionMapped }}
            </span>
          </div>
        </template>
      </main-large-dialog>
      <qgis-plugin-dialog v-if="isDialogLoaded" :is-visible.sync="isQgisVisible" />
      <area-report-dialog v-if="areaReportCoordinates?.length" />
    </v-col>
  </v-row>
</template>

<script>
import { sync, get } from 'vuex-pathify';

import QrcodeVue from 'qrcode.vue';

import tools from '@/mixins/tools';
import mobileAppVersion from '@/mixins/mobileAppVersion';
import TheNavbarTopPanelMenu from '@/components/TheNavbarTopPanelMenu';
import TheNavbarToolsPanelItems from '@/components/TheNavbarToolsPanelItems';
import TheNavbarToolsPanelMapInfo from '@/components/TheNavbarToolsPanelMapInfo';
import TheNavbarToolsPanelSearch from '@/components/TheNavbarToolsPanelSearch';
import TheNavbarToolsPanelSearchObjects from '@/components/TheNavbarToolsPanelSearchObjects';
import TheNavbarToolsZdmStreets from '@/components/TheNavbarToolsZdmStreets';
import TheNavbarToolsPanelReverseGeocoder from '@/components/TheNavbarToolsPanelReverseGeocoder';
import TheNavbarToolsPanelSpatialBookmarks from '@/components/TheNavbarToolsPanelSpatialBookmarks';
import TheNavbarToolsSnappingConfigButton from '@/components/TheNavbarToolsSnappingConfigButton';
import TheNavbarToolsMobileButton from '@/components/TheNavbarToolsMobileButton';
import TheNavbarToolsMeasurements from '@/components/TheNavbarToolsMeasurements';
import TheNavbarToolsZdmPowerSourceButton from '@/components/TheNavbarToolsZdmPowerSourceButton';
import TheNavbarToolsPanelUldk from '@/components/TheNavbarToolsPanelUldk';
import TheNavbarToolsPanelInvestmentAreaSearch from '@/components/TheNavbarToolsPanelInvestmentAreaSearch';
import TheNavbarToolsPanelDataboxData from '@/components/TheNavbarToolsPanelDataboxData';
import TheNavbarToolsPanelRoutingService from '@/components/TheNavbarToolsPanelRoutingService';

export default {
  name: 'TheNavbarToolsPanel',
  mixins: [tools, mobileAppVersion],
  components: {
    TheNavbarTopPanelMenu,
    TheNavbarToolsPanelItems,
    TheNavbarToolsPanelMapInfo,
    QrcodeVue,
    TheNavbarToolsMeasurements,
    TheNavbarToolsPanelSearch,
    TheNavbarToolsZdmStreets,
    TheNavbarToolsPanelReverseGeocoder,
    TheNavbarToolsPanelSpatialBookmarks,
    TheNavbarToolsSnappingConfigButton,
    TheNavbarToolsPanelRoutingService,
    TheNavbarToolsMobileButton,
    TheNavbarToolsPanelSearchObjects,
    TheNavbarToolsZdmPowerSourceButton,
    MainLargeDialog: () => import('@/components/MainLargeDialog'),
    qgisPluginDialog: () => import('@/components/QgisPluginDialog'),
    TheNavbarToolsPanelUldk,
    TheNavbarToolsPanelInvestmentAreaSearch,
    TheNavbarToolsPanelDataboxData,
    AreaReportDialog: () => import('@/components/AreaReportDialog'),
  },
  data: () => ({
    isDialogLoaded: false,
    isMobileVisible: false,
    isQgisVisible: false,
    taskTools: [
      {
        name: 'singleTask',
        isToolWorking: false,
      },
      {
        name: 'multiTasks',
        isToolWorking: false,
      },
    ],
  }),
  computed: {
    documentsLayerId: get('admin/modulesMapping@documentation.documentation_layer_id'),
    isMeasurementsToolActive: sync('tools/toolStatus@isMeasurementsToolActive'),
    isCoordinatesToolActive: sync('tools/toolStatus@isCoordinatesToolActive'),
    isFetched: get('sidebar/isFetched'),
    parcelsProvider: get('admin/settingsValues@parcels_provider'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    activeTool: get('tools/activeTool'),
    areaReportCoordinates: get('search/areaReportCoordinates'),
  },
  methods: {
    turnOffCoordinatesInteraction() {
      if (this.isCoordinatesToolActive) {
        this.$root.$emit('turnOffCoordinatesInteraction');
      }
    },
  },
  mounted() {
    this.$root.$off('MOBILE-action');
    this.$root.$off('QGIS_PLUGIN-action');
    this.$root.$off('addDocument-action');
    this.$root.$on('MOBILE-action', () => {
      this.isDialogLoaded = true;
      this.isMobileVisible = true;
    });
    this.$root.$on('QGIS_PLUGIN-action', () => {
      this.isDialogLoaded = true;
      this.isQgisVisible = true;
    });
    this.$root.$on('addDocument-action', () => {
      if (this.documentsLayerId) {
        this.$router.push({
          name: 'layerFeature',
          params: { lid: this.documentsLayerId, fid: 'new' },
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @include border(bottom);
  padding: 7px 0;
}
.main-large-dialog {
  .v-list-item__title {
    line-height: 30px !important;
  }
}
.background {
  @include activeToolButtonStyle();
}
.app-version-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.59);
}
</style>
