import { get } from 'vuex-pathify';

export default {
  computed: {
    currentLayerId: get('layers/currentLayer@id'),
    permissions: get('users/toolsPermissions'),
    settings: get('admin/settingsValues'),
    additionalModules: get('admin/additionalModules'),
    hasHistoricWaterUsage: get('admin/modulesMapping@billing.has_historic_water_usage_data'),
    layers: get('layers/layers'),
    locksAnalysisLayersIds: get('admin/modulesMapping@failures.cutoff_analysis_layers_ids'),
    modulesMapping: get('admin/modulesMapping'),
    projects: get('layers/projects'),
    customMenus: get('admin/settingsValues@custom_app_menu'),
    hasLocksAnalysisLayersPermissions() {
      const locksAnalysisLayersIds = this.locksAnalysisLayersIds || {};
      for (const layer of this.$_objectToArray(locksAnalysisLayersIds)) {
        if (!this.layers[layer]) {
          return false;
        }
      }
      return true;
    },
    computedBillingTools() {
      const billingsTools = [
        {
          name: 'legalization',
          setting: 'billings_legalization_enabled',
          historicWaterUsage: false,
        },
        {
          name: 'waterDissolutionDma',
          setting: 'billings_water_dissolution_dma_zone_enabled',
          historicWaterUsage: false,
        },
        {
          name: 'waterDissolutionWatermeters',
          setting: 'billings_water_dissolution_watermeters_enabled',
          historicWaterUsage: true,
        },
        {
          name: 'billingAnomalyDetection',
          setting: 'billings_anomaly_detection_enabled',
          historicWaterUsage: true,
        },
      ];
      return billingsTools
        .filter(tool => !tool.historicWaterUsage || this.hasHistoricWaterUsage)
        .filter(tool => this.settings[tool.setting])
        .map(tool => {
          return { name: tool.name };
        });
    },
    computedFailuresTools() {
      const failuresTools = [
        ...(this.additionalModules.FAILURES?.enabled &&
        this.additionalModules.FAILURES?.configured &&
        this.settings.failures_module_enabled
          ? [
              {
                name: 'failures',
                setting: 'failures_dispatcher_enabled',
              },
            ]
          : []),
        ...(this.additionalModules.FAILURES_2?.enabled &&
        this.additionalModules.FAILURES_2?.configured &&
        this.settings.failures_2_module_enabled
          ? [
              {
                name: 'failuresTwo',
                setting: 'failures_2_module_enabled',
              },
            ]
          : []),
        ...(this.additionalModules.FAILURES?.enabled &&
        this.additionalModules.FAILURES?.configured &&
        this.settings.failures_module_enabled &&
        this.hasLocksAnalysisLayersPermissions
          ? [
              {
                name: 'locksAnalysis',
                setting: 'failures_cutoff_enabled',
              },
            ]
          : []),
      ];
      return failuresTools
        .filter(tool => this.settings[tool.setting])
        .map(tool => {
          return { name: tool.name };
        });
    },
    menus() {
      return [
        {
          name: 'FAILURES',
          enableModuleName: ['FAILURES', 'FAILURES_2'],
          enableSettingName: ['failures_module_enabled', 'failures_2_module_enabled'],
          onePermissionRequired: true,
          isAuthorized: true,
          tools: this.computedFailuresTools,
        },
        {
          name: 'tasks',
          enableModuleName: ['TASKS'],
          enableSettingName: ['tasks_module_enabled'],
          isAuthorized: true,
          isToolWorking: true,
        },
        {
          name: 'folders',
          enableSettingName: ['folders_module_enabled'],
          enableModuleName: ['FOLDERS'],
          isAuthorized: true,
          isToolWorking: true,
        },
        {
          name: 'billing',
          enableModuleName: ['BILLINGS'],
          enableSettingName: ['billings_module_enabled'],
          tools: this.computedBillingTools,
          isAuthorized: true,
        },
        {
          name: 'rziMonitoring',
          isToolWorking: true,
          hasCustomAction: true,
          enableSettingName: ['rzi_monitoring_module_enabled'],
          enableModuleName: ['RZI_MONITORING'],
        },
        {
          name: 'transmission_easements',
          verboseName: this.$i18n.t('navbar.TRANSMISSION_EASEMENT'),
          enableSettingName: ['transmission_easement_module_enabled'],
          enableModuleName: ['TRANSMISSION_EASEMENT'],
          isAuthorized: true,
          isToolWorking: true,
        },
        {
          name: 'transmission_easements2',
          verboseName: this.$i18n.t('navbar.TRANSMISSION_EASEMENT_2_MODULE'),
          enableSettingName: ['transmission_easement_2_module_enabled'],
          enableModuleName: ['TRANSMISSION_EASEMENT_2_MODULE'],
          isAuthorized: true,
          isToolWorking: true,
        },
        {
          name: 'model',
          enableModuleName: ['WATER_MODELING'],
          enableSettingName: ['water_modeling_module_enabled'],
          isAuthorized: true,
          tools: [
            {
              name: localStorage.getItem(`scenarioId-water`) ? 'modelingResultsScenario' : 'modelingResults',
              isActive:
                this.$route.params.netType === 'water' &&
                ['modelingResults', 'modelingResultsScenario', 'modelingResultsFeature'].includes(this.$route.name),
              routeParams: {
                netType: 'water',
                ...(localStorage.getItem(`scenarioId-water`)
                  ? {
                      scenarioId: JSON.parse(localStorage.getItem(`scenarioId-water`)),
                    }
                  : {}),
              },
            },
            ...(this.additionalModules.WATER_DATA?.enabled &&
            this.additionalModules.WATER_DATA?.configured &&
            this.settings.water_data_module_enabled
              ? [
                  {
                    name: 'graphGeneration',
                    isToolWorking: true,
                  },
                  {
                    name: 'modelingNetworkCheck',
                    isToolWorking: true,
                  },
                  {
                    name: 'modelingPatterns',
                    isToolWorking: true,
                  },
                  {
                    name: 'modelingCurves',
                    isToolWorking: true,
                  },
                  {
                    name: 'modelingRules',
                    isToolWorking: true,
                  },
                  {
                    name: 'modelingOptions',
                    isToolWorking: true,
                    hasCustomAction: true,
                  },
                  ...(this.settings.epanet_calibration_data_enabled
                    ? [
                        {
                          name: 'calibrationFilesGeneration',
                          isToolWorking: true,
                        },
                      ]
                    : []),
                  ...(this.settings.epanet_fire_fighting_enabled
                    ? [
                        {
                          name: this.currentLayerId ? 'fireSimulationLayer' : 'fireSimulation',
                          isToolWorking: true,
                          routeParams: {
                            ...(this.currentLayerId
                              ? {
                                  lid: this.currentLayerId,
                                }
                              : {}),
                          },
                          isActive: false,
                        },
                      ]
                    : []),
                  ...(this.settings.epanet_water_quality_enabled
                    ? [
                        {
                          name: 'waterModelingQuality',
                          isToolWorking: true,
                          routeParams: {
                            netType: 'water',
                          },
                          isActive: false,
                        },
                      ]
                    : []),
                ]
              : []),
          ],
        },
        {
          name: 'investments',
          enableModuleName: ['INVESTMENTS'],
          enableSettingName: ['investments_module_enabled'],
          isToolWorking: true,
          isAuthorized: true,
        },
        {
          name: 'zdmFailureReport',
          verboseName: this.$i18n.t('navbar.zdmFailureReport'),
          enableModuleName: ['ZDM_FAILURES_REPORT'],
          enableSettingName: ['zdm_failures_report_module_enabled'],
          isToolWorking: true,
          isAuthorized: true,
        },
        {
          name: 'zdm_failures',
          isToolWorking: true,
          enableModuleName: ['ZDM_FAILURES'],
          enableSettingName: ['zdm_failures_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'exportInterCAD',
          hasCustomAction: true,
          enableModuleName: ['INTERCAD_EXPORT'],
          isToolWorking: true,
        },
        {
          name: 'information',
          enableModuleName: ['INFO'],
          isToolWorking: true,
        },
        {
          name: 'modelingSewer',
          enableModuleName: ['SEWER_MODELING'],
          enableSettingName: ['sewer_modeling_module_enabled'],
          isAuthorized: true,
          tools: [
            ...(this.settings.sewer_modeling_tool_enabled
              ? [
                  {
                    name: localStorage.getItem(`scenarioId-sewer`) ? 'modelingResultsScenario' : 'modelingResults',
                    verboseName: this.$i18n.t('sidebar.sewerModelingResults'),
                    isActive:
                      this.$route.params.netType === 'sewer' &&
                      ['modelingResults', 'modelingResultsScenario', 'modelingResultsFeature'].includes(
                        this.$route.name
                      ),
                    routeParams: {
                      netType: 'sewer',
                      ...(localStorage.getItem(`scenarioId-sewer`)
                        ? {
                            scenarioId: JSON.parse(localStorage.getItem(`scenarioId-sewer`)),
                          }
                        : {}),
                    },
                  },
                ]
              : []),
            ...(this.settings.swmm_inp_export_enabled
              ? [
                  {
                    name: 'sewerGraphGeneration',
                    isToolWorking: true,
                  },
                ]
              : []),
          ],
        },
        {
          name: this.currentLayerId ? 'locationLabReportsLayer' : 'locationLabReports',
          enableModuleName: ['LOCATION_LAB'],
          enableSettingName: ['location_lab_module_enabled'],
          isAuthorized: true,
          isToolWorking: true,
          routeParams: {
            ...(this.currentLayerId
              ? {
                  lid: this.currentLayerId,
                }
              : {}),
          },
        },
        {
          name: 'zdmReports',
          enableModuleName: ['ZDM_REPORTS'],
          enableSettingName: ['zdm_reports_module_enabled'],
          isAuthorized: true,
          tools: [
            {
              name: 'zdmSelectionReport',
              isToolWorking: true,
            },
            {
              name: this.currentLayerId ? 'zdmCustomReportsLayer' : 'zdmCustomReports',
              isActive: this.$route.name === 'zdmCustomReportsLayer' || this.$route.name === 'zdmCustomReports',
              routeParams: {
                ...(this.currentLayerId
                  ? {
                      lid: this.currentLayerId,
                    }
                  : {}),
              },
              isToolWorking: true,
            },
          ],
        },
        {
          name: 'zdmEditor',
          enableModuleName: ['ZDM_SESSIONS'],
          enableSettingName: ['zdm_sessions_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'sewerAnalysis',
          enableModuleName: ['SEWER_ANALYSIS'],
          enableSettingName: ['sewer_analysis_module_enabled'],
          tools: [
            {
              name: 'sewerDataProfile',
            },
            {
              name: 'sewerDataInvertedGradient',
            },
            {
              name: 'sewerDataDownstream',
            },
            {
              name: 'sewerDataUpstream',
            },
            ...(this.settings.sewer_congestion_enabled
              ? [
                  {
                    name: 'congestionAnalysis',
                  },
                ]
              : []),
            ...(this.settings.sewer_upstream_sewage_volume_enabled
              ? [
                  {
                    name: 'upstreamSewageVolume',
                  },
                ]
              : []),
            ...(this.settings.sewer_upstream_sewage_outpouring_enabled
              ? [
                  {
                    name: 'upstreamSewageOutpouring',
                  },
                ]
              : []),
          ],
        },
        {
          name: 'waterAnalysis',
          verboseName: this.$i18n.t('navbar.waterAnalysis'),
          tools: [
            ...(this.additionalModules.WATER_NETWORK_PROFILE?.enabled &&
            this.settings.water_network_profile_module_enabled
              ? [
                  {
                    name: 'waterNetworkProfile',
                    verboseName: this.additionalModules['WATER_NETWORK_PROFILE']?.verbose_name,
                    isToolWorking: true,
                  },
                ]
              : []),
            ...(this.additionalModules.WATER_HAMMER?.enabled && this.settings.water_hammer_module_enabled
              ? [
                  {
                    name: 'waterHammer',
                    verboseName: this.additionalModules['WATER_HAMMER']?.verbose_name,
                    isToolWorking: true,
                  },
                ]
              : []),
          ],
        },
        {
          name: 'SCADA',
          verboseName: this.$i18n.t('navbar.SCADA3'),
          enableModuleName: ['SCADA_JUNCTIONS', 'SCADA3'],
          enableSettingName: ['scada_junctions_module_enabled', 'scada_3_module_enabled'],
          onePermissionRequired: true,
          isAuthorized: true,
          tools: [
            ...(this.settings.scada_junctions_module_enabled && this.additionalModules.SCADA_JUNCTIONS?.enabled
              ? [
                  {
                    name: 'smetPoints',
                    verboseName: this.additionalModules['SCADA_JUNCTIONS']?.verbose_name,
                    isToolWorking: true,
                    hasCustomAction: true,
                  },
                ]
              : []),
            ...(this.settings.scada_3_module_enabled && this.additionalModules.SCADA3?.enabled
              ? [
                  {
                    name: 'scadaManager',
                    isToolWorking: true,
                    hasCustomAction: true,
                  },
                ]
              : []),
          ],
        },
        {
          name: 'CUSTOM_REPORTS',
          enableModuleName: ['CUSTOM_REPORTS'],
          enableSettingName: ['custom_reports_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'usableArea',
          enableModuleName: ['OZE_USABLE_AREA_MODULE'],
          isAuthorized: true,
          isToolWorking: true,
          component: () => import('@/components/TheNavbarToolsPanelUsableArea.vue'),
        },
        {
          name: 'WATER_BALANCE_MODULE',
          enableModuleName: ['WATER_BALANCE_MODULE'],
          enableSettingName: ['water_balance_module_enabled'],
          onePermissionRequired: true,
          isAuthorized: true,
          tools: [
            ...(this.settings.water_balance_dma_enabled
              ? [
                  {
                    name: 'waterBalanceDma',
                    isToolWorking: true,
                  },
                ]
              : []),
            ...(this.settings.water_balance_iwa_enabled
              ? [
                  {
                    name: 'waterBalanceIwa',
                    isToolWorking: true,
                  },
                ]
              : []),
            ...(this.settings.water_balance_balance_enabled
              ? [
                  {
                    name: 'waterBalanceZone',
                    isToolWorking: true,
                  },
                ]
              : []),
            ...(this.settings.water_balance_reports_enabled
              ? [
                  {
                    name: 'waterBalanceReports',
                    isToolWorking: true,
                    hasCustomAction: true,
                  },
                ]
              : []),
          ],
        },
        {
          name: 'netDevelopment',
          enableModuleName: ['WATER_NETWORK_DEVELOPMENT_MODULE'],
          enableSettingName: ['water_network_development_module_enabled'],
          isAuthorized: true,
          tools: [
            ...(this.settings.epanet_junctions_demand_enabled
              ? [
                  {
                    name: 'quickCheck',
                    isToolWorking: true,
                  },
                ]
              : []),
            ...(this.settings.epanet_development_planning_enabled
              ? [
                  {
                    name: 'developmentPlanning',
                    isToolWorking: true,
                  },
                ]
              : []),
          ],
        },
        {
          name: 'geonotes',
          enableModuleName: ['GEONOTES_MODULE'],
          enableSettingName: ['geonotes_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'fixedAssets',
          enableModuleName: ['FIXED_ASSETS_MODULE'],
          enableSettingName: ['fixed_assets_module_enabled'],
          isAuthorized: true,
          moduleLayerId: this.modulesMapping?.fixed_assets?.fixed_assets_datasource?.settings?.layer_id,
        },
        {
          name: 'automaticDigitization',
          component: () => import('@/components/TheNavbarToolsPanelPreciseDigitizing.vue'),
          enableModuleName: ['AUTOMATIC_DIGITIZATION'],
          enableSettingName: ['automatic_digitization_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'watermeters',
          verboseName: this.$i18n.t('navbar.watermeters'),
          enableModuleName: ['UNASSIGNED_WATERMETERS_MODULE'],
          enableSettingName: ['unassigned_watermeters_module_enabled'],
          isAuthorized: true,
          tools: [
            ...(this.projects[this.settings?.unassigned_watermeters_project] &&
            this.layers[
              this.modulesMapping?.unassigned_watermeters?.unassigned_watermeters_partitions_datasource?.settings
                ?.layer_id
            ]
              ? [
                  {
                    name: 'unassignedWatermeters',
                    isToolWorking: true,
                    verboseName: this.additionalModules['UNASSIGNED_WATERMETERS_MODULE']?.verbose_name,
                    routeParams: {
                      lid: this.modulesMapping?.unassigned_watermeters.unassigned_watermeters_partitions_datasource
                        .settings.layer_id,
                    },
                  },
                ]
              : []),
          ],
        },
        {
          name: 'infrastructureCollisions',
          enableModuleName: ['INFRASTRUCTURE_COLLISIONS'],
          enableSettingName: ['infrastructure_collisions_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'fresnelZone',
          enableModuleName: ['FRESNEL_AREA'],
          enableSettingName: ['fresnel_area_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'visibilityAnalysis',
          enableModuleName: ['VIEWSHED'],
          enableSettingName: ['viewshed_area_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'failureAnnouncements',
          enableModuleName: ['FAILURE_ANNOUNCEMENTS'],
          isAuthorized: true,
        },
        {
          name: 'dataTrees',
          enableModuleName: ['DATA_TREE_MODULE'],
          enableSettingName: ['data_tree_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'streetLampInventory',
          enableModuleName: ['ILS_SESSIONS_MODULE'],
          enableSettingName: ['street_lamp_inventory_module_enabled'],
          isAuthorized: true,
        },
        {
          name: 'locationAnalysis',
          enableModuleName: ['LOCATION_ANALYSIS_MODULE'],
          isAuthorized: true,
        },
        {
          name: 'areaReport',
          component: () => import('@/components/AreaReportPanel.vue'),
          enableModuleName: ['AREA_REPORT_MODULE'],
          enableSettingName: ['area_report_module_enabled'],
          isAuthorized: true,
        },
      ];
    },
  },
  data: () => ({
    filteredMenus: [],
  }),
  methods: {
    filterPanels(items) {
      return items.filter(menu => {
        const { onePermissionRequired = false, moduleLayerId = null, moduleProjectId = null } = menu;
        const hasPermission = menu.enableModuleName
          ? onePermissionRequired
            ? menu.enableModuleName.find(name => this.permissions[name]?.main_value > 0) || false
            : menu.enableModuleName.every(name => this.permissions[name]?.main_value > 0)
          : true;
        const hasSetting = menu.enableSettingName
          ? onePermissionRequired
            ? menu.enableSettingName.find(name => this.settings[name]) || false
            : menu.enableSettingName.every(name => this.settings[name])
          : true;
        const isConfigured = menu.enableModuleName
          ? onePermissionRequired
            ? menu.enableModuleName.find(name => this.additionalModules[name]?.configured) || false
            : menu.enableModuleName.every(name => this.additionalModules[name]?.configured)
          : true;
        const isEnabled = menu.enableModuleName
          ? onePermissionRequired
            ? menu.enableModuleName.find(name => this.additionalModules[name]?.enabled) || false
            : menu.enableModuleName.every(name => this.additionalModules[name]?.enabled)
          : true;
        const hasEmptyTools = menu.tools && menu.tools.length < 1;
        const hasModuleLayerPermission = !(moduleLayerId && moduleLayerId !== 0) || this.layers[moduleLayerId];
        const hasModuleProjectPermission =
          !(moduleProjectId && moduleProjectId !== 0) || this.projects[moduleProjectId];
        return (
          hasSetting &&
          hasPermission &&
          isConfigured &&
          isEnabled &&
          !hasEmptyTools &&
          hasModuleLayerPermission &&
          hasModuleProjectPermission
        );
      });
    },
    getModuleVerboseName(moduleName) {
      return this.additionalModules[moduleName]?.verbose_name || '';
    },
  },
  mounted() {
    this.filteredMenus = this.filterPanels([
      ...this.menus,
      ...(this.customMenus ? JSON.parse(JSON.stringify(this.customMenus)) : []),
    ])
      .map(menu => {
        if (menu.tools) {
          menu.tools.sort((a, b) =>
            (a.verboseName || this.$i18n.t(`navbar.${a?.name}`)).localeCompare(
              b.verboseName || this.$i18n.t(`navbar.${b?.name}`)
            )
          );
        }
        return {
          ...menu,
          verboseName:
            menu.verboseName ||
            this.getModuleVerboseName(menu.enableModuleName?.[0]) ||
            this.$i18n.t(`navbar.${menu?.name}`),
        };
      })
      .sort((a, b) => a.verboseName.localeCompare(b.verboseName));
  },
};
