<template>
  <v-row
    no-gutters
    class="wrapper justify-center align-center"
    style="height: 100%"
    :class="{ 'print-wrapper': isQuickPrint }"
  >
    <v-col cols="10">
      <v-row no-gutters class="flex-nowrap">
        <v-col cols="auto" class="py-1 px-2 d-flex justify-center align-center">
          <img ref="logo" @click="goToStart" style="max-height: 64px; cursor: pointer" :src="url" />
        </v-col>
        <v-col>
          <v-row no-gutters class="pl-2" :class="{ 'print-navbar_title': isQuickPrint }">
            <v-col cols="10">
              <v-subheader
                class="pa-0 text-h6 font-weight-regular text-truncate d-block text-left"
                style="height: auto; font-size: 18px !important"
                v-text="appName"
              />
            </v-col>
          </v-row>
          <v-row no-gutters :class="{ 'no-print': isQuickPrint }">
            <template v-for="(menu, idx) in filteredMenus">
              <v-col v-if="menu.tools" :key="idx" cols="auto">
                <the-navbar-top-panel-menu :level="0" v-bind="menu" :path="menu.name" />
              </v-col>
              <v-col v-else-if="menu.component" :key="idx" cols="auto">
                <component :is="menu.component" v-bind="menu" />
              </v-col>
              <v-col v-else :key="idx" cols="auto">
                <the-navbar-top-panel-menu-button v-bind="menu" />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }" v-if="alarmsVisible">
      <alarms-button />
    </v-col>
    <v-col cols="auto" :class="{ 'no-print': isQuickPrint }" v-if="unassignedWatermetersVisible">
      <unassigned-watermeters-button />
    </v-col>
    <v-col cols="auto" class="mr-6">
      <v-row no-gutters>
        <template v-for="button of filteredTopPanelButton">
          <the-navbar-top-panel-info-button
            :key="button.name"
            v-bind="button"
            :badge-value="badgeValues[button.name]"
            :class="{ 'no-print': isQuickPrint }"
          />
        </template>
      </v-row>
    </v-col>
    <export-inter-cad-dialog :is-visible.sync="isIntercadDialogVisible" v-if="isIntercadDialogLoaded" />
    <modeling-options-dialog :is-visible.sync="isOptionsDialogVisible" v-if="isOptionsDialogLoaded" />
    <scada-points-dialog :is-visible.sync="isScadaDialogVisible" v-if="isScadaDialogLoaded" />
    <scada-manager-dialog :is-visible.sync="isScadaManagerVisible" v-if="isScadaManagerLoaded" />
    <quick-task :is-visible.sync="isTaskDialogVisible" v-if="isTaskDialogLoaded" :selected-features="taskData" />
    <water-balance-reports-dialog :is-visible.sync="isWaterBalanceDialogVisible" v-if="isWaterBalanceDialogLoaded" />
  </v-row>
</template>

<script>
import { get, call } from 'vuex-pathify';

import logo from '@/mixins/logo';
import menus from '@/mixins/menus';

import { monitoring } from '@/assets/js/rziData';

import AlarmsButton from '@/components/AlarmsButton';
import UnassignedWatermetersButton from '@/components/UnassignedWatermetersButton';
import DotsMenu from '@/components/DotsMenu';
import TheNavbarTopPanelMenu from '@/components/TheNavbarTopPanelMenu';
import TheNavbarTopPanelMenuButton from '@/components/TheNavbarTopPanelMenuButton';
import TheNavbarTopPanelInfoButton from '@/components/TheNavbarTopPanelInfoButton';
import NavbarMenuTool from '@/components/NavbarMenuTool';

export default {
  name: 'TheNavbarTopPanel',
  mixins: [logo, menus],
  components: {
    AlarmsButton,
    UnassignedWatermetersButton,
    ExportInterCadDialog: () => import('@/components/ExportInterCadDialog'),
    QuickTask: () => import('@/components/QuickTask'),
    ScadaPointsDialog: () => import('@/components/ScadaPointsDialog'),
    ModelingOptionsDialog: () => import('@/components/ModelingOptionsDialog'),
    ScadaManagerDialog: () => import('@/components/ScadaManagerDialog'),
    WaterBalanceReportsDialog: () => import('@/components/WaterBalanceReportsDialog'),
    TheNavbarTopPanelMenu,
    DotsMenu,
    TheNavbarTopPanelMenuButton,
    TheNavbarTopPanelInfoButton,
    NavbarMenuTool,
  },
  data: () => ({
    url: `${import.meta.env.VUE_APP_API_URL}/settings/logo`,
    isOptionsDialogLoaded: false,
    isOptionsDialogVisible: false,
    isScadaDialogLoaded: false,
    isScadaDialogVisible: false,
    isTaskDialogLoaded: false,
    isTaskDialogVisible: false,
    isIntercadDialogLoaded: false,
    isIntercadDialogVisible: false,
    isScadaManagerLoaded: false,
    isScadaManagerVisible: false,
    isUserSettingsDialogLoaded: false,
    isUserSettingsDialogVisible: false,
    isWaterBalanceDialogLoaded: false,
    isWaterBalanceDialogVisible: false,
    taskData: {},
    badgeValues: {
      tasks: '0',
      failures: '0',
    },
  }),
  computed: {
    currentLayerId: get('layers/currentLayer@id'),
    scadaAlarmsAdminEnabled: get('admin/settingsValues@scada_alarms_module_enabled'),
    scadaAlarmsSuperAdminEnabled: get('admin/additionalModules@SCADA_ALARMS.enabled'),
    modulesPermissions: get('users/toolsPermissions'),
    projectId: get('layers/projectId'),
    failuresStatuses: get('admin/modulesMapping@failures.statuses'),
    failuresLayerId: get('admin/modulesMapping@failures.layer_id'),
    tasksLayerId: get('admin/modulesMapping@tasks.layer_id'),
    isAdmin: get('users/currentUser@is_admin'),
    appName: get('admin/settingsValues@app_name'),
    currentUser: get('users/currentUser'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    layers: get('layers/layers'),
    projects: get('layers/projects'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    unassignedWatermetersProjectId: get('admin/settingsValues@unassigned_watermeters_project'),
    unassignedWatermetersLayerId: get(
      'admin/modulesMapping@unassigned_watermeters.unassigned_watermeters_partitions_datasource.settings.layer_id'
    ),
    unassignedWatermetersAdminEnabled: get('admin/settingsValues@unassigned_watermeters_module_enabled'),
    unassignedWatermetersSuperAdminEnabled: get('admin/additionalModules@UNASSIGNED_WATERMETERS_MODULE.enabled'),
    unassignedWatermetersConfigured: get('admin/additionalModules@UNASSIGNED_WATERMETERS_MODULE.configured'),
    unassignedWatermetersPermissions() {
      return this.modulesPermissions['UNASSIGNED_WATERMETERS_MODULE']?.main_value > 0;
    },
    unassignedWatermetersVisible() {
      return (
        this.unassignedWatermetersSuperAdminEnabled &&
        this.unassignedWatermetersAdminEnabled &&
        this.unassignedWatermetersConfigured &&
        this.unassignedWatermetersPermissions &&
        this.projects[this.unassignedWatermetersProjectId] &&
        this.layers[this.unassignedWatermetersLayerId]
      );
    },
    scadaAlarmsPermissions() {
      return this.modulesPermissions['SCADA_ALARMS']?.main_value > 0;
    },
    alarmsVisible() {
      return this.scadaAlarmsSuperAdminEnabled && this.scadaAlarmsAdminEnabled && this.scadaAlarmsPermissions;
    },
    theNavbarTopPanelInfoButtons() {
      const failuresStatusFilters = [this.failuresStatuses?.active, this.failuresStatuses?.new];
      return [
        {
          name: 'tasks',
          otherTableRoutes: ['task'],
          icon: 'mdi-account-hard-hat',
          enableModuleName: ['TASKS'],
          enableSettingName: ['tasks_module_enabled'],
          permissionPrefix: 'tasks',
          additionalTableFilterValues: { assigned_user: [this.currentUser.id] },
          layerId: this.tasksLayerId,
          filters: {
            $EQUAL: {
              'tasks_task.assigned_user': {
                value: this.currentUser.id,
              },
            },
          },
        },
        {
          name: 'failures',
          otherTableRoutes: ['failure', 'newFailure'],
          icon: 'mdi-wrench',
          enableModuleName: ['FAILURES'],
          enableSettingName: ['failures_module_enabled'],
          permissionPrefix: 'tasks',
          additionalTableFilterValues: { status: failuresStatusFilters },
          layerId: this.failuresLayerId,
          filters: {
            $IN: {
              'failures_failure.status': {
                value: failuresStatusFilters,
              },
            },
          },
          settingName: 'failures_dispatcher_enabled',
        },
      ];
    },
    filteredTopPanelButton() {
      return this.filterPanels(this.theNavbarTopPanelInfoButtons).filter(
        panel => !panel.settingName || this.settings[panel.settingName]
      );
    },
  },
  methods: {
    getFeatures: call('layers/getLayerFeatures'),
    async getBadgeValue({ layerId, filters, name }) {
      try {
        const r = await this.getFeatures({
          features_filter: filters,
          layer_id: layerId,
          params: {
            with_features: false,
            with_geometry: false,
            with_total_count: true,
            with_count: false,
          },
        });
        this.badgeValues[name] = r.data.data.total_features_count.toString();
      } catch (error) {
        this.badgeValues[name] = '0';
        console.log('  error', error);
      }
    },
    getBadgesValues() {
      this.filteredTopPanelButton.forEach(badge => this.getBadgeValue(badge));
    },
    refreshNavbarInfoButtons(buttonsNames = ['failures', 'tasks']) {
      this.filteredTopPanelButton
        .filter(button => buttonsNames.includes(button.name))
        .forEach(badge => this.getBadgeValue(badge));
    },
    goToStart() {
      this.$router.push({ name: 'map', query: { project: this.projectId } });
    },
    openRziMonitoringTab() {
      this.$store.set('table/ADD_TABLE_TAB!', {
        value: `rziMonitoring`,
        name: 'Monitoring',
      });
    },
  },
  created() {
    this.getBadgesValues();
  },
  mounted() {
    this.$root.$on('modelingOptions-action', () => {
      this.isOptionsDialogLoaded = true;
      this.isOptionsDialogVisible = true;
    });
    this.$root.$on('rziMonitoring-action', () => {
      if (!this.currentLayerId) {
        let layerId =
          this.projectLayers.find(layer => layer.data_source_name === monitoring.mainArea.datasource)?.id ||
          Object.values(this.layers).find(layer => layer.data_source_name === monitoring.mainArea.datasource)?.id;
        this.$router.push({ name: 'layerTable', params: { lid: layerId }, query: this.$route.query });
        this.$root.$once('tableDataRowsFetched', () => {
          this.openRziMonitoringTab();
        });
      } else {
        this.openRziMonitoringTab();
      }
    });
    this.$root.$on('exportInterCAD-action', () => {
      this.isIntercadDialogLoaded = true;
      this.isIntercadDialogVisible = true;
    });
    this.$root.$on('smetPoints-action', () => {
      this.isScadaDialogLoaded = true;
      this.isScadaDialogVisible = true;
    });
    this.$root.$on('scadaManager-action', () => {
      this.isScadaManagerLoaded = true;
      this.isScadaManagerVisible = true;
    });
    this.$root.$on('openTaskDialog', value => {
      this.taskData = value;
      this.isTaskDialogLoaded = true;
      this.isTaskDialogVisible = true;
    });
    this.$root.$on('waterBalanceReports-action', () => {
      this.isWaterBalanceDialogLoaded = true;
      this.isWaterBalanceDialogVisible = true;
    });
    this.$root.$off('refreshNavbarInfoButtons');
    this.$root.$on('refreshNavbarInfoButtons', this.refreshNavbarInfoButtons);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @include border(bottom);
}
</style>
