/* eslint-disable */
/*
  Variables are stored in JS file, because there might be some cases where they
  will be required outside .vue file or before Vue instance had been initialized (no `this` access).
*/

const defaultAdminTab = 'cockpit';

const defaultEpsg = 'EPSG:3857';
const defaultEpsgName = 'EPSG:3857 (Google Mercator)';
const defaultSRID = 3857;
const defaultEpsgDefinition =
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs';
const defaultEpsgExtent = [-20026376.39, -20048966.1, 20026376.39, 20048966.1];

const defaultDocumentationHost = 'https://dokumentacja.gisbox.pl';

const defaultFrontendCoordinateSystems = [
  {
    auth_srid: defaultSRID,
    name: defaultEpsgName,
    proj4text: defaultEpsgDefinition,
  },
  {
    auth_srid: 4326,
    name: 'EPSG:4326 (GPS)',
    proj4text: '',
  }
];

const minEditingZoom = 16;
const defaultMinZoom = 0;
const defaultMaxZoom = 28;

const systemAttributes = [
  'id',
  '_id',
  'history_count',
  'notes_count',
  'attachments_count',
  'create_datetime',
  'create_user',
  'update_datetime',
  'update_user',
  'geom',
];

const dashboardOrtoWmts = {
  parameters: {
    CRS: 'EPSG:2180',
    format: 'image/jpeg',
    options: {
      extent: [118322.29387404403, 116649.52381191216, 896351.9119938755, 806302.0717712911],
      matrixIds: [
        'EPSG:2180:0',
        'EPSG:2180:1',
        'EPSG:2180:2',
        'EPSG:2180:3',
        'EPSG:2180:4',
        'EPSG:2180:5',
        'EPSG:2180:6',
        'EPSG:2180:7',
        'EPSG:2180:8',
        'EPSG:2180:9',
        'EPSG:2180:10',
        'EPSG:2180:11',
        'EPSG:2180:12',
        'EPSG:2180:13',
        'EPSG:2180:14',
        'EPSG:2180:15',
        'EPSG:2180:16',
      ],
      origins: [
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
        [100000, 850000],
      ],
      resolutions: [
        8466.6836, 4233.3418, 2116.6709, 1058.33545, 529.167725, 264.5838625, 132.29193125, 66.145965625,
        26.458386249999997, 13.229193124999998, 6.614596562499999, 2.645838625, 1.3229193125, 0.529167725, 0.2645838625,
        0.13229193125, 0.066145965625,
      ],
      style: '',
      tileSizes: [512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512, 512],
    },
    version: '1.0.0',
  },
  service_layers_names: ['ORTOFOTOMAPA'],
  url: 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/HighResolution',
};

export {
  defaultFrontendCoordinateSystems,
  defaultEpsg,
  defaultEpsgName,
  defaultEpsgDefinition,
  defaultAdminTab,
  defaultEpsgExtent,
  defaultDocumentationHost,
  defaultSRID,
  defaultMinZoom,
  defaultMaxZoom,
  minEditingZoom,
  systemAttributes,
  dashboardOrtoWmts,
};
