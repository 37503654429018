<template>
  <div class="sider-admin-section" :class="{ 'sider-admin-section__hidden': !isSiderOpened }">
    <list-expansion-panels :items="filtersMenus" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { get } from 'vuex-pathify';
import menusAdmin from '@/assets/js/menusAdmin';
import ListExpansionPanels from '@/components/ListExpansionPanels';
export default {
  name: 'SiderAdminSection',
  components: {
    ListExpansionPanels,
  },
  props: {
    isSiderOpened: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    menus: menusAdmin,
  }),
  computed: {
    currentUser: get('users/currentUser'),
    isSuperadmin: get('users/currentUser@is_superadmin'),
    additionalModules: get('admin/additionalModules'),
    filtersMenus() {
      return this.$_filterNestedArray(cloneDeep(this.menus), this.filterMenu, 'items').map(items => {
        if (items.items) {
          items.items = items.items.map(item => {
            const verboseName = item.customNamePath
              ? this.$i18n.t(item.customNamePath)
              : '' || item.enableModuleName?.[0]
                ? this.getModuleVerboseName(item.enableModuleName)
                : '' || this.$i18n.t(`navbar.${item?.name}`);
            return { ...item, verboseName };
          });
          items.items.sort((a, b) => {
            return a.verboseName.localeCompare(b.verboseName);
          });
        }
        return items;
      });
    },
  },
  methods: {
    getModuleVerboseName(moduleName) {
      return this.additionalModules[moduleName[0]]?.verbose_name;
    },
    filterMenu(menu) {
      if (menu?.name === 'superadmin') {
        return this.isSuperadmin;
      } else if (menu?.name === 'devTab') {
        return this.isSuperadmin || this.development;
      } else if (!menu.isAuthorized) {
        return true;
      } else {
        const { oneModuleRequired = false } = menu;
        return oneModuleRequired
          ? menu.enableModuleName.find(name => this.additionalModules[name]?.enabled) || false
          : menu.enableModuleName.every(name => this.additionalModules[name]?.enabled);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sider-admin-section {
  height: 100%;
  transition: opacity 0.2s ease allow-discrete;
}
.sider-admin-section__hidden {
  opacity: 0 !important;
  pointer-events: none !important;
}
</style>
