<template>
  <v-tooltip bottom color="font">
    <template #activator="{ on }">
      <v-icon size="18" v-on="on" @click="openDocumentation(name)" color="primary">mdi-help-circle</v-icon>
    </template>
    {{ $i18n.t('default.goToToolDocumentation') }}
  </v-tooltip>
</template>
<script>
import documentation from '@/mixins/documentation';
export default {
  name: 'DocumentationButton',
  mixins: [documentation],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    openDocumentation(name) {
      if (!this.documentation[name]) return;
      const host = import.meta.env.VUE_APP_DOCUMENTATION_HOST || this.$_config.defaultDocumentationHost;
      window.open(`${host}/${this.documentation[name]}`, '_blank');
    },
  },
};
</script>
