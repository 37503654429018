<template>
  <main-large-dialog
    :is-visible.sync="computedIsVisible"
    :title="title ? title : $i18n.t(isEdit ? 'dialog.editFeature' : 'dialog.addFeature')"
    :agree-text="agreeText ?? (isEdit ? 'dialog.agreeSave' : 'dialog.agreeAdd')"
    :loading="isDialogLoading"
    :is-valid="isFormValid"
    @agree="handleAddEdit"
    v-bind="dialogData"
  >
    <template #body>
      <sidebar-row equal-padding class="pr-5">
        <slot name="prepend_form" />
      </sidebar-row>
      <v-form ref="dataForm" v-model="isFormValid" style="width: 100%">
        <feature-card-data :elements="formSchema" v-model="values" is-editing-on disable-width-limit equal-padding>
          <template v-for="(value, key) in $scopedSlots" #[key]="scope">
            <slot :name="key" v-bind="scope" />
          </template>
        </feature-card-data>
      </v-form>
    </template>
  </main-large-dialog>
</template>

<script>
import defaultValues from '@/mixins/defaultValues';
import prepareAttributesFeatureCardMixin from '@/mixins/prepareAttributesFeatureCardMixin';
import { get, call } from 'vuex-pathify';
import { systemAttributes } from '@/assets/js/variables';

import FeatureCardData from '@/components/FeatureCardData';
import SidebarRow from '@/components/SidebarRow';

export default {
  name: 'AddEditFeatureDialog',
  mixins: [defaultValues, prepareAttributesFeatureCardMixin],
  components: {
    FeatureCardData,
    SidebarRow,
    MainLargeDialog: () => import('@/components/MainLargeDialog'),
  },
  data: () => ({
    isDialogLoading: false,
    values: {},
    isFormValid: false,
    formSchema: [],
  }),
  props: {
    isVisible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    agreeText: {
      type: String,
    },
    featureData: {
      type: Object,
      default: () => ({
        id: null /** Optional - dialog in editing mode if not provided */,
        layer: null /** Optional */,
        datasource: null,
      }),
    },
    /** This should be optional. Expand this component if you need dynamic feature properties with id only */
    featureMetadata: {
      type: Object,
    },
    /**
     * Special keys:
     * - _systems - skips all system attributes
     */
    hiddenAttributes: {
      type: Array,
    },
    geometry: {
      type: Object,
    },
    staticProperties: {
      type: Object,
    },
    customDatasourceSchema: {
      type: Array,
      default: null,
    },
    dialogData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    computedIsVisible: {
      immediate: true,
      handler(nV) {
        if (!nV) return;
        this.prepareData(this.featureData);
      },
    },
  },
  computed: {
    layers: get('layers/layers'),
    layersMetadata: get('layers/metadata'),
    computedIsVisible: {
      get() {
        return this.isVisible;
      },
      set(nV) {
        this.$emit('update:isVisible', nV);
      },
    },
    isEdit() {
      return !!this.featureData.id;
    },
    isNew() {
      // Used in mixins
      return !this.isEdit;
    },
  },
  methods: {
    addNonTopoFeatureAction: call('layers/addDatasourceFeature'),
    editNonTopoFeatureAction: call('layers/editDatasourceFeature'),
    getHiddenAttributes() {
      return this.hiddenAttributes.reduce(
        (acc, item) => (acc = [...acc, ...(item === '_systems' ? systemAttributes : [item])]),
        []
      );
    },
    prepareData({ layer, datasource }) {
      this.$refs['dataForm']?.resetValidation();
      this.copyFeatureData = JSON.parse(JSON.stringify(this.featureData));
      const datasourceSchema = this.layersMetadata[datasource].attributes_schema;
      this.formSchema = this.getForm(
        layer ? this.layers[layer]?.form_schema.elements : null,
        this.customDatasourceSchema ?? datasourceSchema.attributes,
        {
          ...(this.hiddenAttributes && {
            filteredOutAttributes: datasourceSchema.attributes
              .filter(attr => this.getHiddenAttributes().includes(attr.name))
              .map(attr => attr.name),
          }),
        }
      ).map(attr => ({
        ...attr,
        ...(Object.keys(this.staticProperties || {}).includes(attr.attribute) && { editable: false }),
      }));
      const geometryAttribute = this.geometry
        ? {
            [datasourceSchema.geometry_name]: this.geometry,
          }
        : {};
      let additionalValues = { ...(this.staticProperties ?? {}), ...geometryAttribute };
      if (!this.featureMetadata) return (this.values = { ...additionalValues });
      const idAttributeName = datasourceSchema.id_name;
      const properties = this.getFlattenProperties(this.featureMetadata, idAttributeName);
      this.values = {
        ...this.getValues(this.formSchema, properties),
        ...additionalValues,
      };
    },
    successCloseDialog() {
      this.computedIsVisible = this.isDialogLoading = false;
    },
    async handleAddEdit() {
      this.isDialogLoading = true;
      try {
        const body = {
          ...this.values,
          ...(this.staticProperties ? this.staticProperties : {}),
        };
        let newData;
        if (this.isEdit) {
          await this.editNonTopoFeatureAction({
            datasource: this.featureData.datasource,
            id: this.featureData.id,
            body: body,
            params: this.featureData.layer ? { layer_id: this.featureData.layer } : {},
          });
        } else {
          newData = await this.addNonTopoFeatureAction({
            datasource: this.featureData.datasource,
            body: body,
            params: this.featureData.layer ? { layer_id: this.featureData.layer } : {},
          });
        }
        Object.keys(this.$listeners).includes('done')
          ? this.$emit('done', this.successCloseDialog, this.featureData, newData?.data?.data)
          : this.successCloseDialog();
      } catch (error) {
        console.error(error);
        this.isDialogLoading = false;
      }
    },
  },
};
</script>
