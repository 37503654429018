export default {
  data: () => ({
    documentation: {
      areaReport: 'knowledge-base/podstawowe-narzedzia/narzedzie-raport-o-terenie',
      databoxData: 'knowledge-base/podstawowe-narzedzia/pobierz-dane-wektorowe-beta',
      routingService: 'knowledge-base/podstawowe-narzedzia/narzedzie-wyznaczanie-tras',
      usableArea: 'knowledge-base/podstawowe-narzedzia/narzedzie-powierzchnia-uzytkowa',
    },
  }),
};
