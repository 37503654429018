import { make } from 'vuex-pathify';
import { map, fromPairs } from 'lodash';
import { defaultSRID } from '@/assets/js/variables';
import { arrayToObject } from '@/assets/js/utility';

import api from '@/services/api';

import Vue from 'vue';
const returnDataFromULdk = data => {
  const lines = data.match(/[^\r\n]+/g);
  if (lines.length <= 1) {
    return {};
  } else {
    lines.shift(0);
    return fromPairs(
      map(lines, line => {
        const lineSplit = line.split('|');
        return [lineSplit[1], { territId: lineSplit[1], name: lineSplit[0] }];
      })
    );
  }
};

const state = {
  results: {},
  areaReportCoordinates: [],
  parcelReportCoordinates: [],
  /**
   * 0 - initial
   * 1 - loading
   * 2 - fetched
   */
  uldkAttributesMappingsFetchStatus: 0,
  uldkAttributesMappings: {},
  uldkToolsAttributesMappings: {},
};

const mutations = {
  ...make.mutations(state),
  SET_RESULTS: (state, payload) => {
    const { layer, value } = payload;
    Vue.set(state.results, layer, value);
  },
};

const actions = {
  async searchByLayer(store, payload) {
    const { params, layer } = payload;
    return await api.get(`app_data/search/${layer}`, { params });
  },
  async searchParcelsByLayer(store, payload) {
    const { params } = payload;
    return await api.get(`parcels/search`, { params });
  },
  async getUldkCommunities(store, district) {
    return fetch(`https://uldk.gugik.gov.pl/service.php?obiekt=gmina&wynik=gmina,teryt&teryt=${district}`)
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async getUldkDistricts(store, voivodeship) {
    return fetch(`https://uldk.gugik.gov.pl/service.php?obiekt=powiat&wynik=powiat,teryt&teryt=${voivodeship}`)
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async getUldkPrecincts(store, community) {
    return fetch(`https://uldk.gugik.gov.pl/service.php?obiekt=obreb&wynik=obreb,teryt&teryt=${community}`)
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async getUldkVoivodeships() {
    return fetch('https://uldk.gugik.gov.pl/service.php?obiekt=wojewodztwo&wynik=wojewodztwo,teryt')
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async searchUldkParcel(
    store,
    { territ, resultSrid = defaultSRID, params = ['numer', 'teryt', 'geom_wkt'], mapped = false } = {}
  ) {
    return fetch(
      `https://uldk.gugik.gov.pl/service.php?obiekt=dzialka&wynik=${params.toString()}&teryt=${territ}&srid=${resultSrid}`
    )
      .then(r => r.text())
      .then(data => {
        const lines = data.match(/[^\r\n]+/g);
        if (lines.length <= 1) {
          return [];
        } else {
          if (lines[0].indexOf('-1') >= 0) {
            return [];
          }
          lines.shift(0);
          if (mapped) {
            const valueArray = map(lines, line => line.split('|'))[0];
            return Object.assign(...params.map((k, i) => ({ [k]: valueArray[i] })));
          } else {
            return map(lines, line => line.split('|'));
          }
        }
      });
  },
  async searchUldkParcelByXY(store, { coords, resultSrid = defaultSRID }) {
    const { x, y, epsg, params = ['geom_wkt', 'numer', 'teryt'] } = coords;
    return fetch(
      `https://uldk.gugik.gov.pl/service.php?obiekt=dzialka&wynik=${params.toString()}&request=GetParcelByXY&xy=${x},${y},${epsg}&srid=${resultSrid}`
    )
      .then(r => r.text())
      .then(data => {
        const lines = data.match(/[^\r\n]+/g);
        if (lines.length <= 1) {
          return [];
        } else {
          if (lines[0].indexOf('-1') >= 0) {
            if (lines[1]) {
              return { error: lines[1] };
            }
            return [];
          }
          lines.shift(0);
          const valueArray = map(lines, line => line.split('|'))[0];
          return Object.assign(...params.map((k, i) => ({ [k]: valueArray[i] })));
        }
      });
  },
  async getParcelCollisions(store, payload) {
    const { geometry, layers, params } = payload;
    const r = await api.post('/parcel_report', { geometry, layers }, { params });
    return r.data.data;
  },
  async getAreaCollisions(store, payload) {
    const { geometry, layers, params } = payload;
    const r = await api.post('/area_report/collisions', { geometry, layers }, { params });
    return r.data.data;
  },
  async getAreaLandCover(store, payload) {
    const r = await api.post('/area_report/land_cover', payload);
    return r.data.data;
  },
  async searchStreets(store, payload) {
    const { params } = payload;
    const r = await api.get(`zdm/streets/search`, { params });
    return r.data.data;
  },
  async searchModelingResults(store, payload) {
    const { params, netType, scenarioId, objectType } = payload;
    const r = await api.get(`${netType}/modeling_results/${scenarioId}/${objectType}`, { params });
    return r.data.data;
  },
  async searchGoogleAddresses(store, payload) {
    const r = await api.post('/google/geocode', payload);
    return r.data.data;
  },
  async searchAddressGugik(store, payload) {
    const { coords, srid } = payload;
    return fetch(
      `https://services.gugik.gov.pl/uug/?request=GetAddressReverse&location=POINT(${coords[0]} ${coords[1]})&srid=${srid}`
    )
      .then(r => r.json())
      .then(data => data);
  },
  async searchElevationGugik(store, coords) {
    return fetch(`https://services.gugik.gov.pl/nmt/?request=GetHByXY&x=${coords[0]}&y=${coords[1]}`)
      .then(r => r.json())
      .then(data => data);
  },
  async getUldkAttributesMappings({ commit }) {
    const r = await api.get('parcels/mappings');
    commit('SET_ULDK_ATTRIBUTES_MAPPINGS', arrayToObject(r.data.data, 'datasource'));
  },
  async addUldkAttributesMapping({ dispatch }, payload) {
    await api.post('parcels/mappings', payload);
    await dispatch('getUldkAttributesMappings');
  },
  async deleteUldkAttributesMapping({ dispatch }, id) {
    await api.delete(`parcels/mappings/${id}`);
    await dispatch('getUldkAttributesMappings');
  },
  async editUldkAttributesMapping({ dispatch }, { id, payload } = {}) {
    await api.put(`parcels/mappings/${id}`, payload);
    await dispatch('getUldkAttributesMappings');
  },
  async getUldkToolsAttributesMappings({ commit }) {
    const r = await api.get('uldk_tools/mappings');
    commit('SET_ULDK_TOOLS_ATTRIBUTES_MAPPINGS', arrayToObject(r.data.data, 'datasource'));
  },
  async addUldkToolsAttributesMapping({ dispatch }, payload) {
    await api.post('uldk_tools/mappings', payload);
    await dispatch('getUldkToolsAttributesMappings');
  },
  async deleteUldkToolsAttributesMapping({ dispatch }, id) {
    await api.delete(`uldk_tools/mappings/${id}`);
    await dispatch('getUldkToolsAttributesMappings');
  },
  async editUldkToolsAttributesMapping({ dispatch }, { id, payload } = {}) {
    await api.put(`uldk_tools/mappings/${id}`, payload);
    await dispatch('getUldkToolsAttributesMappings');
  },
  async getRoutingServiceRoute(store, payload) {
    const r = await api.post('routing_service/route', payload);
    return r.data.data;
  },
  async getFeaturePreview(store, payload) {
    const r = await api.post('area_report/feature_preview', payload);
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
