import { make } from 'vuex-pathify';

const state = {
  toolStatus: {
    isIdentificationToolActive: false,
    isMeasurementsToolActive: false,
    isCoordinatesToolActive: false,
    isParcelReportToolActive: false,
    isAreaReportToolActive: false,
    isStreetViewToolActive: false,
    isGoogleMapsToolActive: false,
    isReverseGeocoderToolActive: false,
    isQuickPrintToolActive: false,
    isModelingResultsIdentificationActive: false,
    isSelectionActive: false,
    isCardInfoPrintActive: false,
    isSpatialBookmarksToolActive: false,
    isSnappingConfigToolActive: true,
    isGeolocationToolActive: false,
    isRoutingServiceToolActive: false,
  },
  activeTool: undefined,
  isActiveToolNotReplaceable: false,
  isPreviousViewDisabled: false,
  isNextViewDisabled: false,
  isAdminContentOverflowing: undefined,
  snappingConfigToolToleranceValue: 10,
  snappingConfigOld: {},
  isPressed: {},
  isKeyBindingActive: false,
  modalOverlayLevel: 0,
};

const mutations = {
  ...make.mutations(state),
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
