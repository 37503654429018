<template>
  <v-list class="pb-4 pt-0" :class="isChild ? 'childWrapper' : 'wrapper'" dense>
    <template v-for="item in items">
      <span :key="item.name" v-if="currentUser.is_superadmin || !item.superadmin">
        <v-list-item v-if="item.items" class="pa-0">
          <v-expansion-panels flat tile accordion>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="px-0 pl-0 pr-1 list-expansion-header"
                :class="item.isSubList ? 'pr-10 py-0' : ' py-0'"
              >
                <the-navbar-top-panel-menu-item :key="item.name" v-bind="item" :is-admin="true">
                </the-navbar-top-panel-menu-item
              ></v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <list-expansion-panels :items="item.items" :is-child="true"> </list-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item>
        <the-navbar-top-panel-menu-item
          v-else-if="!item.isToolWorking"
          :key="item.name"
          v-bind="item"
          :is-admin="true"
          style="width: 100%"
          class="pa-0"
        >
        </the-navbar-top-panel-menu-item>
        <the-navbar-button v-bind="item" :key="item.name" v-else>
          <template #default="{ state }">
            <the-navbar-top-panel-menu-item
              :key="item.name"
              v-bind="{ ...item, state }"
              style="width: 100%"
              :is-admin="true"
            >
            </the-navbar-top-panel-menu-item>
          </template>
        </the-navbar-button>
      </span>
    </template>
  </v-list>
</template>

<script>
import { get } from 'vuex-pathify';
import TheNavbarTopPanelMenuItem from '@/components/TheNavbarTopPanelMenuItem';
import TheNavbarButton from '@/components/TheNavbarButton';

export default {
  name: 'ListExpansionPanels',
  components: {
    TheNavbarTopPanelMenuItem,
    TheNavbarButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentUser: get('users/currentUser'),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}
.childWrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}
.list-expansion-header {
  max-height: 40px;
  min-height: 40px;
}
:deep(.v-expansion-panel-header__icon) {
  padding-right: 1rem;
}
:deep(.v-expansion-panels) {
  z-index: 0 !important;
}
::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: unset;
  }
  v-expansion-panel-header__icon {
    padding-right: 20px;
  }
}
</style>
