import { make } from 'vuex-pathify';

const state = {
  tableHeight: 1,
  floatingFilterOpened: true,
  mapExtentFilter: false,
  currentTableSort: null,
  currentTableTab: 'attributesTable',
  tabs: [
    {
      value: 'attributesTable',
      name: null,
      appendName: null,
      preventClose: true,
    },
  ],
};

const mutations = {
  ...make.mutations(state),
  RESET_TABLE_TABS(state) {
    if (state.tabs.length <= 1) return;
    state.tabs = [state.tabs[0]];
    state.currentTableTab = 'attributesTable';
  },
  ADD_TABLE_TAB(state, metadata) {
    const tabExists = !!state.tabs.find(tab => tab.value === metadata.value);
    if (tabExists) {
      state.currentTableTab = metadata.value;
      return;
    }
    state.tabs = [...state.tabs, metadata];
    state.currentTableTab = metadata.value;
  },
  CLOSE_TABLE_TAB(state, value) {
    const deletedTab = state.currentTableTab;
    const previousTabValue =
      state.tabs[state.tabs.findIndex(tab => tab.value === deletedTab) - 1]?.value ?? 'attributesTable';
    const filteredTabs = [...state.tabs.filter(tab => tab.value !== value)];
    state.tabs = filteredTabs;
    if (deletedTab === value) state.currentTableTab = previousTabValue;
  },
  UPDATE_TAB_PROPERTIES(state, data) {
    const { tabName, metadata } = data;
    let tabsCopy = [...state.tabs];
    let tabToChangeIndex = tabsCopy.findIndex(tab => tab.value === tabName);
    if (!tabToChangeIndex) return;
    tabsCopy[tabToChangeIndex] = {
      ...tabsCopy[tabToChangeIndex],
      ...metadata,
    };
    state.tabs = tabsCopy;
  },
  SET_TABLE_PROPERTIES(state, metadata) {
    state.tabs = [
      {
        ...state.tabs[0],
        ...metadata,
      },
      ...state.tabs.slice(1),
    ];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
