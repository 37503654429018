import { call, sync, get } from 'vuex-pathify';
import { Draw } from 'ol/interaction';
import { Style, Stroke } from 'ol/style';

export default {
  computed: {
    isAreaReportToolActive: sync('tools/toolStatus@isAreaReportToolActive'),
    areaReportCoordinates: get('search/areaReportCoordinates'),
  },
  data: () => ({
    areaReportLoaded: false,
    areaReportVisible: false,
  }),
  methods: {
    searchUldkAreaByXY: call('search/searchUldkAreaByXY'),
    addAreaReportLayer(geometry) {
      this.addCustomLayer(geometry, 'areaReport', {
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#BF360C',
            width: 3,
          }),
        }),
      });
    },
    clearAreaReportInteraction() {
      this.id = 1;
      this.map.removeInteraction(this.getInteractionByName('areaReportCoordinates'));
      this.deactivateToolHandler('areaReport');
      this.isCoordinatesToolActive = false;
    },
    removeAreaReportLayer() {
      if (this.getLayerById('areaReport')) {
        this.map.removeLayer(this.getLayerById('areaReport'));
      }
    },
    toggleAreaReport(value) {
      if (value && this.activeTool !== 'areaReport') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'areaReport') {
        this.deactivateToolHandler('areaReport');
      }
      this.$nextTick(() => {
        this.isAreaReportToolActive = value;
        this.dettachCursorMoveHandler();
        if (value) {
          if (!this.getLayerById('areaReport')) {
            this.addAreaReportLayer();
          }
          const areaReportInteraction = new Draw({
            source: this.getLayerById('areaReport').getSource(),
            type: 'Point',
          });
          areaReportInteraction.set('name', 'areaReportCoordinates');
          areaReportInteraction.on('drawend', e => {
            this.clearCustomLayer('areaReportCoordinates');
            const coordinates = e.feature.getGeometry().getCoordinates();
            e.feature.setId(1);
            this.$store.set('search/SET_AREA_REPORT_COORDINATES!', coordinates);
            this.areaReportLoaded = this.areaReportVisible = true;
          });
          this.map.addInteraction(areaReportInteraction);

          this.activeTool = 'areaReport';
        } else {
          this.clearAreaReportInteraction();
        }
      });
    },
  },
  mounted() {
    this.$root.$on('areaReport-action', this.toggleAreaReport);
  },
};
